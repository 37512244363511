<template>
  <div :class="['sg-tabs', type === 'button' ? 'sg-tabs-button' : '', color]">
    <div class="is-flex" v-if="!type" :class="{
      'w-100': extended
    }">
      <div class="sg-tab" :class="{
       'sg-active': (value === (typeof item === 'object' ? item.value : item)),
       'is-flex-grow-1': extended
    }" v-for="(item, key) in items" :key="key" @click="$emit('input', typeof item === 'object' ? item.value : item)">
        {{ typeof item === 'object' ? item[label] : item }}
      </div>
    </div>
    <div v-if="type === 'button'" class="scroll-x">
      <b-button :type="(value === (typeof item === 'object' ? item.value : item)) ? activeClass : defaultClass" class="mr-2"
                v-for="(item, key) in items" :key="key" @click="$emit('input', typeof item === 'object' ? item.value : item)">
        {{ typeof item === 'object' ? item[label] : item }}
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SgTab",
  props: {
    value: {},
    items: {},
    type: {
      type: String,
      default: null
    },
    activeClass: {
      type: String,
      default: 'is-danger is-light'
    },
    defaultClass: {
      type: String,
      default: 'is-text'
    },
    extended: Boolean,
    label: {
      type: String,
      default: 'label',
    },
    color: String,
  }
}
</script>
