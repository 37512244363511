<template>
  <div class="card">
    <div class="card-content">
      <ValidationObserver ref="form">
        <form @submit.prevent="handleSubmit">
          <header class="mb-2">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <h2 class="is-size-6 has-text-weight-bold has-text-primary mr-3">{{ $t('download_report') }}</h2>
              <div @click="$emit('close')">
                <b-icon class="cursor-pointer" icon="close-thick"/>
              </div>
            </div>
          </header>

          <section class="my-2">
            <ValidationProvider :name="$t('finance.account')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('finance.account')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-autocomplete
                    open-on-focus
                    keep-first
                    :data="filteredAccount"
                    :placeholder="`${$t('search')} ${$t('finance.account')}`"
                    field="name"
                    @typing="searchAccount"
                    v-model="form.search_account"
                    @select="selectAccount">
                </b-autocomplete>
              </b-field>
            </ValidationProvider>
            <b-field>
              <b-switch v-model="form.account_date" @input="handleAccountDate">
                Generate berdasarkan tanggal buka dan tutup buku
              </b-switch>
            </b-field>
            <ValidationProvider :name="$t('date')" :rules="{'required': !form.account_date}" v-slot="{ errors }">
              <b-field :label="$t('date')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datepicker
                    :placeholder="`${$t('select')} ${$t('date')}`"
                    icon="calendar-text"
                    locale="id-ID"
                    range
                    v-model="form.date"
                    :disabled="form.account_date"
                >
                </b-datepicker>
              </b-field>
            </ValidationProvider>
          </section>

          <footer class="has-text-right">
            <b-button type="is-secondary is-light" @click="$emit('close')">
              {{ $t('cancel') }}
            </b-button>
            <b-button class="ml-2" type="is-primary" native-type="submit">
              Download
            </b-button>
          </footer>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "FinanceReportForm",
  data: () => {
    return {
      form: {
        search_account: '',
        selected_accounts: [],
      },
      filteredAccount: [],
    }
  },
  computed: {
    ...mapState('finance', [
      'account',
    ]),

    ...mapState('farm', [
      'selectedFarm'
    ]),

    farm_id() {
      return this.$route.params.farm_id
    }
  },
  methods: {
    handleAccountDate() {
      if (this.form.account_date && this.form.account) {
        this.form.date = [
          new Date(this.form.account.created_at),
          this.form.account.end_date ? new Date(this.form.account.end_date) : new Date(),
        ]
      } else {
        this.form.date = []
      }

      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },

    searchAccount() {
      this.filteredAccount = this.account.filter(e => {
        return e.name
            .toString()
            .toLowerCase()
            .indexOf(this.form.search_account.toLowerCase()) >= 0
      })
    },

    selectAccount(option) {
      this.form.account = option
      this.$nextTick(() => {
        this.handleAccountDate()
      })
    },

    handleSubmit() {
      this.$refs.form.validate().then(success => {
        if (success) {
          let routeData = this.$router.resolve({
            path: `/farm/${this.farm_id}/finance/report`,
            query: {
              farm_id: this.selectedFarm.farm_id,
              farm_name: this.selectedFarm.name,
              account: JSON.stringify({
                account_id: this.form.account.account_id,
                name: this.form.account.name,
              }),
              dates: JSON.stringify(this.form.account_date ? [
                this.form.account.created_at,
                this.form.account.end_date,
              ] : [
                this.$timestamp(this.form.date[0]),
                this.$timestamp(this.form.date[1]),
              ])
            }
          });
          window.open(routeData.href, '_blank');
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 600px !important;
  max-width: 100%;
}
</style>
