<template>
  <div>
    <sg-top-overlay :back-url="`/farm/${selectedFarm.farm_id}`">
      <div class="has-text-centered-mobile">
        <b-button type="is-primary" outlined class="mr-3" @click="downloadReport">
          {{ $t('download_report') }}
        </b-button>
        <b-button tag="router-link" :to="`/farm/${selectedFarm.farm_id}/finance/settings`" type="is-primary" outlined>
          {{ $t('settings') }}
        </b-button>
      </div>
    </sg-top-overlay>

    <b-modal
        v-model="downloadReportModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <finance-report-form is-modal @close="props.close"/>
      </template>
    </b-modal>

    <h2 class="is-size-5 has-text-weight-bold has-text-dark">Finance</h2>
    <div class="is-divider"></div>

    <div v-if="account.length">
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <div>
          <p class="has-text-weight-bold">{{ $t('finance.choose_account') }}</p>
          <b-dropdown position="is-bottom-right" append-to-body aria-role="menu" trap-focus class="custom-dropdown"
                      ref="accountDropdown" style="min-height: 30px">
            <div class="is-flex is-align-items-center" slot="trigger" slot-scope="{ active }" role="button">
          <span class="is-size-5 has-text-weight-bold has-text-dark is-flex is-align-items-center">
            {{ selectedAccount.name }} <b-tag rounded type="is-danger is-light" class="ml-2" v-if="!isOpen">{{ $t('finance.closed') }}</b-tag>
          </span>
              <b-icon class="ml-2" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
            </div>
            <div style="min-width: 240px">
              <div class="sg-scroller">
                <b-dropdown-item aria-role="listitem" custom
                                 class="is-flex is-justify-content-space-between is-align-items-center py-0 pl-0"
                                 v-for="item in account" :key="item.id">
                  <div class="is-flex-grow-1 cursor-pointer pl-4 py-2" @click="selectAccount(item)">
                    <p class="excerpt">
                      {{ item.name }}
                      <b-tag rounded type="is-danger is-light" class="ml-2" v-if="item.end_date !== 0">{{ $t('finance.closed') }}</b-tag>
                    </p>
                  </div>
                  <div class="is-flex">
                    <b-button type="is-text"
                              slot="trigger"
                              @click="editAccount(item)"
                              icon-left="pencil-outline"
                              size="is-small"
                              :title="$t('edit')">
                    </b-button>
                    <b-button type="is-text"
                              slot="trigger"
                              icon-left="trash-can-outline"
                              class="has-text-danger"
                              @click="destroyAccount(item.account_id)"
                              size="is-small"
                              :title="$t('delete')">
                    </b-button>
                  </div>
                </b-dropdown-item>
              </div>
              <b-dropdown-item
                  class="custom-dropdown-item"
                  aria-role="menu-item"
                  :focusable="false"
                  custom>
                <b-button type="is-danger"
                          icon-left="plus"
                          class="is-gradient btn-action mt-1 has-text-weight-light"
                          @click="addAccount"
                          expanded>{{ $t('finance.add_account') }}
                </b-button>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

        <b-button type="is-primary" outlined @click="closeAccount" v-if="isOpen">
          {{ $t('finance.close_account') }}
        </b-button>
      </div>

      <sg-tab v-model="selectedAction" class="border-top-0 mb-5 mt-2"
              :items="['Dashboard', $t('finance.transaction'), $t('finance.statistic')]"></sg-tab>

      <div v-if="loaded">
        <finance-dashboard v-if="selectedAction === 'Dashboard'"/>
        <finance-transaction v-if="selectedAction === $t('finance.transaction')" type="income"/>
        <finance-cash-flow v-else-if="selectedAction === $t('finance.statistic')"/>
      </div>
    </div>

    <div class="is-flex is-flex-direction-column is-align-items-center pt-4" v-else>
      <p class="is-size-5 has-text-weight-bold has-text-grey">{{ $t('finance.no_account') }}</p>
      <div class="is-divider" style="max-width: 300px"/>

      <div class="is-flex finance-steps">
        <div class="has-text-centered">
          <b-icon icon="plus" size="is-medium"/>
          <p v-html="$t('finance.step1')"></p>
        </div>
        <div class="has-text-centered">
          <b-icon icon="pencil-outline" size="is-medium"/>
          <p v-html="$t('finance.step2')"></p>
        </div>
        <div class="has-text-centered">
          <b-icon icon="chart-arc" size="is-medium"/>
          <p v-html="$t('finance.step3')"></p>
        </div>
      </div>

      <b-button type="is-primary is-gradient" @click="addAccount">{{ $t('finance.add_account') }}</b-button>
    </div>

    <b-modal
        v-model="addAccountModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <finance-account-form @close="props.close" action="add" @submit="getAccount"/>
      </template>
    </b-modal>

    <b-modal
        v-model="editAccountModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <finance-account-form @close="props.close" :selected-account="selectedAccountEdit" :action="action"
                              @submit="getAccount"/>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SgTopOverlay from "@/components/Sg/SgTopOverlay";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SgTab from "@/components/Sg/SgTab";
import FinanceTransaction from "@/components/Finance/FinanceTransaction";
import FinanceReportForm from "@/components/Finance/FinanceReportForm";

export default {
  name: "FinanceIndex",
  components: {
    FinanceReportForm,
    FinanceTransaction,
    FinanceAccountForm: () => import("@/components/Finance/FinanceAccountForm"),
    SgTab,
    FinanceDashboard: () => import("@/components/Finance/FinanceDashboard"),
    FinanceCashFlow: () => import("@/components/Finance/FinanceCashFlow"),
    SgTopOverlay
  },
  computed: {
    ...mapGetters('farm', [
      'selectedFarm',
    ]),
    ...mapGetters('finance', [
      'account',
      'selectedAccount',
      'isOpen',
    ]),
  },
  data: () => {
    return {
      selectedAction: 'Dashboard',
      addAccountModal: false,
      editAccountModal: false,
      selectedAccountEdit: null,
      action: null,
      downloadReportModal: false,
      loaded: true,
    }
  },
  methods: {
    ...mapActions('finance', [
      'getAccount',
      'deleteAccount',
    ]),

    ...mapMutations('finance', [
      'UPDATE_SELECTED_ACCOUNT',
    ]),

    downloadReport() {
      this.downloadReportModal = true
    },

    loadData() {
      this.$loading()
      this.getAccount().finally(() => {
        this.$loading(false)
      })
    },

    addAccount() {
      if (this.$refs.accountDropdown) this.$refs.accountDropdown.isActive = false
      this.addAccountModal = true
    },

    closeAccount() {
      this.selectedAccountEdit = this._.cloneDeep(this.selectedAccount)
      this.action = 'close'
      this.editAccountModal = true
    },

    editAccount() {
      this.$refs.accountDropdown.isActive = false
      this.selectedAccountEdit = this._.cloneDeep(this.selectedAccount)
      this.action = 'edit'
      this.editAccountModal = true
    },

    destroyAccount(id) {
      this.$refs.accountDropdown.isActive = false
      this.$delete(async () => {
        this.$loading()
        this.deleteAccount(id).finally(() => {
          this.loadData()
        })
      })
    },

    selectAccount(account) {
      this.loaded = false
      this.UPDATE_SELECTED_ACCOUNT(account)
      this.$refs.accountDropdown.isActive = false
      this.$nextTick(() => {
        this.loaded = true
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .finance-steps {
    margin-bottom: 3rem;
    margin-top: 1rem;
    font-weight: bold;

    > div {
      padding: 0 10px;
      width: 250px;
      max-width: 100%;

      .icon {
        padding: 1.8rem;
        background-color: rgba(255, 102, 0, 0.09);
        border-radius: 1000px;
        color: #FF6600;
        margin-bottom: 1rem;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 2rem;
      > div {
        padding: 0;
        margin-bottom: 2.5rem;
      }
    }
  }
}
</style>