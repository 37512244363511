<template>
  <div>
    <b-modal v-model="isModalActive" has-modal-card>
      <template #default="props">
        <div class="card">
          <div class="card-content">
            <header class="mb-3">
              <div class="is-flex is-justify-content-space-between is-align-items-center">
                <h4 class="is-size-5 has-text-primary has-text-weight-bold mr-3">{{ $t('image') }}</h4>
                <div @click="closeModal(props)">
                  <b-icon class="cursor-pointer" icon="close-thick"/>
                </div>
              </div>
            </header>

            <sg-carousel :images="images"/>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SgCarousel from "@/components/Sg/SgCarousel";

export default {
  name: "SgCarouselModal",
  components: {SgCarousel},
  props: {
    images: Array,
    value: Boolean,
  },
  data: () => {
    return {
      isModalActive: false
    }
  },
  watch: {
    value(value) {
      this.isModalActive = value
    },
    isModalActive(value) {
      if (value === false) this.closeModal()
    }
  },
  methods: {
    closeModal(props = null) {
      if (props) props.close()
      this.$emit('input', false)
    }
  }
}
</script>
