<template>
  <b-carousel :pause-info="false" :indicator-inside="false">
    <b-carousel-item v-for="(image, key) in images" :key="key">
            <span class="image">
              <img :src="image" alt="">
            </span>
    </b-carousel-item>
    <template slot="indicators" slot-scope="props">
        <span class="image">
            <img :src="images[props.i]" alt="">
        </span>
    </template>
  </b-carousel>
</template>

<script>
export default {
  name: "SgCarousel",
  props: {
    images: Array
  }
}
</script>

<style lang="scss">
.carousel {
  .carousel-item {
    .image img {
      max-height: 60vh !important;
      width: auto !important;
      object-fit: contain;
    }
  }

  .carousel-indicator {
    padding-top: 1rem!important;

    .indicator-item {
      border: 1px solid transparent;
      border-radius: 8px;
      overflow:hidden;
      padding: 4px;
    }

    .is-active {
      border: 1px solid #FF6600;
    }

    .image img {
      height: 62px !important;
      width: auto !important;
      object-fit: contain;
    }
  }
}
</style>
