var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('header',{staticClass:"mb-2"},[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center"},[_c('h2',{staticClass:"is-size-6 has-text-weight-bold has-text-primary"},[_vm._v(_vm._s(_vm.title))]),_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1)]),_c('h2',{staticClass:"has-text-weight-bold has-text-grey mb-2"},[_vm._v(" "+_vm._s(_vm.selectedFarm.name)+" - "+_vm._s(_vm.selectedAccount.name)+" ")]),(!_vm.selectedTransaction.transaction_record_id)?_c('sg-tab',{staticClass:"border-top-0 mb-4",attrs:{"color":"is-primary","extended":"","items":[{value: 'income', label: _vm.$t('finance.income')}, {value: 'expenses', label: _vm.$t('finance.expenses')}]},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}):_vm._e()],1),_c('section',{staticClass:"my-2"},[_c('ValidationProvider',{attrs:{"name":"Nominal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Nominal","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{directives:[{name:"currency",rawName:"v-currency",value:(_vm.currencyOptions),expression:"currencyOptions"}],attrs:{"type":"text","id":"nominal","placeholder":"Input Nominal"},on:{"input":_vm.handleInputNominal},model:{value:(_vm.nominal),callback:function ($$v) {_vm.nominal=$$v},expression:"nominal"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Kategori","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Kategori","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-select',{attrs:{"placeholder":"Pilih Kategori"},model:{value:(_vm.form.category_transaction_id),callback:function ($$v) {_vm.$set(_vm.form, "category_transaction_id", $$v)},expression:"form.category_transaction_id"}},_vm._l((_vm.transactionCategories),function(category,key){return _c('option',{key:key,domProps:{"value":category.category_transaction_id}},[_vm._v(" "+_vm._s(category.category_transaction_name)+" ")])}),0)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Waktu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Waktu","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datetimepicker',{attrs:{"placeholder":"Pilih Waktu","icon":"calendar-text","locale":"id-ID"},model:{value:(_vm.form.transaction_date),callback:function ($$v) {_vm.$set(_vm.form, "transaction_date", $$v)},expression:"form.transaction_date"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Metode Pembayaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Metode Pembayaran","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-select',{attrs:{"placeholder":"Pilih Metode Pembayaran"},model:{value:(_vm.form.metode),callback:function ($$v) {_vm.$set(_vm.form, "metode", $$v)},expression:"form.metode"}},[_c('option',{attrs:{"value":"transfer"}},[_vm._v("Transfer")]),_c('option',{attrs:{"value":"cash"}},[_vm._v("Cash")])])],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"Notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Notes","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"id":"notes","placeholder":"Input Notes"},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", _vm._n($$v))},expression:"form.note"}})],1)]}}])}),_c('b-field',{attrs:{"label":_vm.$t('image')}},[_c('div',{staticClass:"is-flex is-flex-wrap-wrap"},[_c('b-upload',{staticClass:"sg-uploader mr-4 mb-4",attrs:{"multiple":"","drag-drop":"","accept":"image/*"},model:{value:(_vm.form.images),callback:function ($$v) {_vm.$set(_vm.form, "images", $$v)},expression:"form.images"}},[_c('b-icon',{attrs:{"icon":"image-outline","size":"is-large"}}),_c('b-icon',{staticClass:"add-icon",attrs:{"icon":"plus"}})],1),_vm._l((_vm.images),function(image,k){return _c('div',{key:k,staticClass:"sg-uploader mr-4 mb-4"},[(image.photo)?_c('div',{staticClass:"img-overlay"},[_c('div',{on:{"click":function($event){return _vm.deleteFiles(k, image)}}},[_c('b-icon',{staticClass:"remove-icon",attrs:{"icon":"trash-can-outline"}})],1),_c('img',{attrs:{"src":image.photo,"alt":""}})]):_c('div',{staticClass:"img-overlay"},[_c('div',{on:{"click":function($event){return _vm.deleteFiles(k)}}},[_c('b-icon',{staticClass:"remove-icon",attrs:{"icon":"trash-can-outline"}})],1),_c('img',{attrs:{"src":image,"alt":""}})])])})],2)])],1),_c('footer',{staticClass:"has-text-right"},[_c('b-button',{attrs:{"native-type":"submit","type":"is-primary is-gradient"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }