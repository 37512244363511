<template>
  <div class="card">
    <div class="card-content">
      <ValidationObserver ref="form">
        <form @submit.prevent="handleSubmit">
          <header class="mb-2">
            <div class="is-flex is-justify-content-space-between is-align-items-center">
              <h2 class="is-size-6 has-text-weight-bold has-text-primary">{{ title }}</h2>
              <div @click="$emit('close')">
                <b-icon class="cursor-pointer" icon="close-thick"/>
              </div>
            </div>

            <h2 class="has-text-weight-bold has-text-grey mb-2">
              {{ selectedFarm.name }} - {{ selectedAccount.name }}
            </h2>

            <sg-tab class="border-top-0 mb-4" color="is-primary" extended
                    v-if="!selectedTransaction.transaction_record_id"
                    :items="[{value: 'income', label: $t('finance.income')}, {value: 'expenses', label: $t('finance.expenses')}]"
                    v-model="category"/>

          </header>
          <section class="my-2">
            <ValidationProvider name="Nominal" rules="required" v-slot="{ errors }">
              <b-field label="Nominal" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input
                    @input="handleInputNominal"
                    v-currency="currencyOptions"
                    type="text" id="nominal" v-model="nominal" placeholder="Input Nominal"></b-input>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Kategori" rules="required" v-slot="{ errors }">
              <b-field label="Kategori" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-select v-model="form.category_transaction_id" placeholder="Pilih Kategori">
                  <option :value="category.category_transaction_id" v-for="(category, key) in transactionCategories"
                          :key="key">
                    {{ category.category_transaction_name }}
                  </option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Waktu" rules="required" v-slot="{ errors }">
              <b-field label="Waktu" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-datetimepicker
                    placeholder="Pilih Waktu"
                    icon="calendar-text"
                    locale="id-ID"
                    v-model="form.transaction_date">
                </b-datetimepicker>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Metode Pembayaran" rules="required" v-slot="{ errors }">
              <b-field label="Metode Pembayaran" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-select v-model="form.metode" placeholder="Pilih Metode Pembayaran">
                  <option value="transfer">Transfer</option>
                  <option value="cash">Cash</option>
                </b-select>
              </b-field>
            </ValidationProvider>
            <ValidationProvider name="Notes" v-slot="{ errors }">
              <b-field label="Notes" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input id="notes" v-model.number="form.note" placeholder="Input Notes"></b-input>
              </b-field>
            </ValidationProvider>
            <b-field :label="$t('image')">
              <div class="is-flex is-flex-wrap-wrap">
                <b-upload v-model="form.images" multiple drag-drop accept="image/*"
                          class="sg-uploader mr-4 mb-4">
                  <b-icon icon="image-outline" size="is-large"></b-icon>
                  <b-icon icon="plus" class="add-icon"></b-icon>
                </b-upload>

                <div v-for="(image,k) in images" :key="k" class="sg-uploader mr-4 mb-4">
                  <div class="img-overlay" v-if="image.photo">
                    <div @click="deleteFiles(k, image)">
                      <b-icon icon="trash-can-outline" class="remove-icon"/>
                    </div>
                    <img :src="image.photo" alt=""/>
                  </div>
                  <div class="img-overlay" v-else>
                    <div @click="deleteFiles(k)">
                      <b-icon icon="trash-can-outline" class="remove-icon"/>
                    </div>
                    <img :src="image" alt=""/>
                  </div>
                </div>
              </div>
            </b-field>
          </section>

          <footer class="has-text-right">
            <b-button native-type="submit" type="is-primary is-gradient">
              {{ $t('save') }}
            </b-button>
          </footer>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import SgTab from "@/components/Sg/SgTab";
import { parse as parseCurrency } from "vue-currency-input";

export default {
  name: "FinanceTransactionForm",
  components: {SgTab},
  props: {
    defaultCategory: String,
  },
  data: () => {
    return {
      currencyOptions: {
        currency: 'IDR',
        locale: 'id',
        distractionFree: false,
        allowNegative: false
      },
      nominal: '',
      form: {
        images: [],
      },
      category: 'income',
      images: [],
      delete_images: [],
    }
  },
  computed: {
    ...mapState('farm', [
      'selectedFarm'
    ]),
    ...mapState('finance', [
      'selectedAccount'
    ]),

    ...mapGetters('finance', [
      'allIncomeCategories',
      'allExpensesCategories',
    ]),

    ...mapState('finance', [
      'selectedTransaction'
    ]),

    transactionCategories() {
      return this.category === 'income' ? this.allIncomeCategories : this.allExpensesCategories
    },

    title() {
      if (!this.selectedTransaction.transaction_record_id) return `${this.$t('add')} ${this.$t('finance.transaction')}`
      else if (this.category === 'income' && this.selectedTransaction.transaction_record_id) return this.$t('finance.income')
      else if (this.category === 'expenses' && this.selectedTransaction.transaction_record_id) return this.$t('finance.expenses')

      return ''
    },
  },
  watch: {
    category() {
      if (this.selectedTransaction && this.selectedTransaction.category_transaction_id && this.transactionCategories.find(e => e.category_transaction_id === this.selectedTransaction.category_transaction_id))
        this.form.category_transaction_id = this.selectedTransaction.category_transaction_id
      else this.form.category_transaction_id = null
      this.$refs.form.reset()
    },
    'form.images': {
      deep: true,
      async handler(values) {
        this.images = []
        if (values && values.length) {
          for (let i = 0; i < values.length; i++) {
            const file = values[i];
            this.images.push(typeof file === 'object' && file.photo ? file : URL.createObjectURL(file));
          }
        }
      }
    },
  },
  created() {
    if (this.defaultCategory) this.category = this.defaultCategory
    if (this.selectedTransaction.transaction_record_id) {
      this.form = {
        ...this.selectedTransaction,
        transaction_date: new Date(this.selectedTransaction.transaction_date),
        images: this.selectedTransaction.photos || [],
      }
      this.nominal = this.selectedTransaction.nominal
    }
  },
  methods: {
    ...mapActions('finance', [
      'addTransaction',
      'updateTransaction',
    ]),

    deleteFiles(index, item = null) {
      if (item) {
        this.$delete(async () => {
          this.delete_images.push(item)
          this.form.images.splice(index, 1)
        }, false, 'Are you sure you want to delete this image?')
      } else this.form.images.splice(index, 1)
    },

    handleInputNominal (value) {
      this.form.nominal = parseCurrency(value, this.currencyOptions)
    },

    handleSubmit() {
      this.$refs.form.validate().then(async success => {
        if (success) {
          this.$loading()
          if (this.selectedTransaction.transaction_record_id) {
            let photos = (await Promise.all(
                this.form.images
                    .filter(e => !e.photo)
                    .map(e => this.$base64(e))
            )).map(e => {
              return {photo: e}
            })

            this.updateTransaction({
              ...this.form,
              photos,
              transaction_date: this.$timestamp(this.form.transaction_date),
              created_at: this.$timestamp(this.form.created_at),
            }).then(() => {
              this.$emit('submit')
              this.$emit('close')
            }).finally(() => {
              this.$loading(false)
            })
          } else {
            let photos = (await Promise.all(this.form.images.map(e => this.$base64(e)))).map(e => {
              return {photo: e}
            })

            this.addTransaction({
              category_transaction_id: this.form.category_transaction_id,
              metode: this.form.metode,
              nominal: this.form.nominal,
              transaction_date: this.$timestamp(this.form.transaction_date),
              note: this.form.note,
              photos,
            }).then(() => {
              this.$emit('submit')
              this.$emit('close')
            }).finally(() => {
              this.$loading(false)
            })
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 600px !important;
  max-width: 100%;
}
</style>
