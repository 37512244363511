<template>
  <div>
    <sg-carousel-modal v-model="photoModal" :images="photos"/>

    <div class="is-flex is-justify-content-space-between is-align-items-flex-start is-flex-wrap-wrap mb-3">
      <div class="columns is-mobile has-text-centered">
        <div class="column">
          <div class="card has-text-success">
            <div class="card-content">
              <p class="is-size-4 has-text-weight-bold">
                {{ $currency(incomeData.reduce((a, b) => a + b.nominal, 0)) }}
              </p>
              <div class="is-divider my-2"></div>
              <div class="is-flex whitespace-nowrap">
                <b-icon icon="currency-usd" class="mr-2"/>
                {{ $t('total') }} {{ $t('finance.income') }}
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card has-text-danger">
            <div class="card-content">
              <p class="is-size-4 has-text-weight-bold">
                {{ $currency(expensesData.reduce((a, b) => a + b.nominal, 0)) }}
              </p>
              <div class="is-divider my-2"></div>
              <div class="is-flex whitespace-nowrap">
                <b-icon icon="cart-outline" class="mr-2"/>
                {{ $t('total') }} {{ $t('finance.expenses') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-button type="is-primary is-gradient" @click="addTransaction" icon-left="plus" v-if="isOpen">
        {{ $t('add') }} {{ $t('finance.transaction') }}
      </b-button>
    </div>

    <div class="is-divider"></div>

    <div class="is-flex is-flex-wrap-wrap is-justify-content-space-between">
      <sg-tab class="mb-3" type="button" :items="categoryOptions" v-model="category" activeClass="is-primary is-light"/>
      <div class="is-flex mb-3">
        <b-input icon="magnify" placeholder="Search" class="mr-2" v-model="search"/>

        <b-datepicker
            v-model="selectedDate"
            :placeholder="$t('date')"
            icon="calendar-text"
            range
            class="mb-2"
            clearable
            locale="id-ID">
          <b-button
              class="ml-auto"
              label="Clear"
              type="is-danger"
              outlined
              @click="selectedDate = []"/>
        </b-datepicker>
      </div>
    </div>
    <b-table :mobile-cards="false" :data="data" bordered>
      <template #empty>
        <p class="has-text-centered">{{ $t('no') }} Data</p>
      </template>

      <b-table-column field="category_transaction_id" label="Kategori" v-slot="props">
        <span class="has-text-weight-bold" :class="{
          'has-text-success': props.row.category.type === 'in',
          'has-text-danger': props.row.category.type === 'out',
        }">{{ getCategory(props.row.category_transaction_id).category_transaction_name }}</span>
      </b-table-column>
      <b-table-column field="created_at" label="Tanggal" v-slot="props">
        {{ $dateFormat(props.row.transaction_date) }}
        {{ $timeFormat(props.row.transaction_date) }}
      </b-table-column>
      <b-table-column field="metode" label="Metode Pembayaran" v-slot="props">
        {{ metodeLabel(props.row.metode) }}
      </b-table-column>
      <b-table-column field="nominal" label="Nominal" v-slot="props">
        {{ $currency(props.row.nominal) }}
      </b-table-column>

      <b-table-column field="image" :label="$t('image')" v-slot="props">
        <div class="is-flex image-list">
          <span @click="showImageModal(props.row.photos)" v-for="(image, key) in props.row.photos" :key="key">
          <img
              v-if="image.photo"
              class="img-sm"
              :src="image.photo"
              alt=""
          />
          </span>
        </div>
      </b-table-column>
      <b-table-column field="note" label="Notes" v-slot="props">
        {{ props.row.note }}
      </b-table-column>
      <b-table-column field="action" label="" v-slot="props">
        <div class="is-flex">
          <b-button class="mr-2" type="is-secondary is-light" icon-left="pencil-outline"
                    @click="editTransaction(props.row)"></b-button>
          <b-button type="is-danger is-light" icon-left="trash-can-outline"
                    @click="deletePopup(props.row.transaction_record_id)"></b-button>
        </div>
      </b-table-column>
    </b-table>

    <b-modal
        v-model="modal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <finance-transaction-form @close="props.close" @submit="getTransaction" :defaultCategory="category"/>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import FinanceTransactionForm from "@/components/Finance/FinanceTransactionForm";
import SgTab from "@/components/Sg/SgTab";
import SgCarouselModal from "@/components/Sg/SgCarouselModal";

export default {
  name: "FinanceTransaction",
  components: {SgCarouselModal, SgTab, FinanceTransactionForm},
  data: () => {
    return {
      search: '',
      modal: false,
      category: 'all',
      selectedDate: [],
      photos: [],
      photoModal: false,
    }
  },
  computed: {
    ...mapState('finance', [
      'selectedAccount'
    ]),
    ...mapState('finance', [
      'transaction'
    ]),
    ...mapGetters('finance', [
      'allIncomeCategories',
      'allExpensesCategories',
      'allCategories',
      'isOpen',
    ]),

    categoryOptions() {
      return [
        {value: 'all', label: this.$t('all')},
        {value: 'income', label: this.$t('finance.income')},
        {value: 'expenses', label: this.$t('finance.expenses')},
      ]
    },

    categories() {
      if (this.category === 'income') return this.allIncomeCategories
      else if (this.category === 'expenses') return this.allExpensesCategories
      return this.allCategories
    },

    data() {
      let categories = this.categories.map(e => e.category_transaction_id)

      let transaction = this._.cloneDeep(this.transaction).filter(e => categories.includes(e.category_transaction_id)).map(e => {
        e.category = this.getCategory(e.category_transaction_id)
        return e
      }).filter(e => {
        return e.category.category_transaction_name
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0 ||
            e.note
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0 ||
            e.nominal
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0 ||
            e.metode
                .toString()
                .toLowerCase()
                .indexOf(this.search.toLowerCase()) >= 0
      }).sort((a, b) => b.transaction_date > a.transaction_date ? 1 : -1)

      if (this.selectedDate.length) {
        let start = this.$startDay(this.selectedDate[0]).valueOf()
        let end = this.$endDay(this.selectedDate[1]).valueOf()
        transaction = transaction.filter(e => e.created_at >= start && e.created_at <= end)
      }

      return transaction
    },

    incomeData() {
      let categories = this.allIncomeCategories.map(e => e.category_transaction_id)
      return this.transaction.filter(e => categories.includes(e.category_transaction_id))
    },

    expensesData() {
      let categories = this.allExpensesCategories.map(e => e.category_transaction_id)
      return this.transaction.filter(e => categories.includes(e.category_transaction_id))
    },
  },

  created() {
    this.$loading()
    Promise.all([
      this.getAccountDetail(),
      this.getTransaction(),
    ]).finally(() => {
      this.$loading(false)
    })
  },

  methods: {
    addTransaction() {
      this.UPDATE_SELECTED_TRANSACTION()
      this.modal = true
    },

    getCategory(id) {
      return this.categories.find(e => e.category_transaction_id === id) || {}
    },

    metodeLabel(value) {
      return value[0].toUpperCase() + value.substr(-value.length + 1)
    },

    ...mapActions('finance', [
      'getTransaction',
      'deleteTransaction',
    ]),

    ...mapActions('finance', [
      'getAccountDetail'
    ]),

    ...mapMutations('finance', [
      'UPDATE_SELECTED_TRANSACTION'
    ]),

    showImageModal(photos) {
      this.photos = photos.map(e => e.photo)
      this.photoModal = true
    },

    loadData() {
      this.$loading()
      this.getTransaction().finally(() => {
        this.$loading(false)
      })
    },

    editTransaction(value) {
      this.UPDATE_SELECTED_TRANSACTION(value)
      this.modal = true
    },

    deletePopup(id) {
      this.$delete(() => {
        this.$loading()
        this.deleteTransaction(id).then(() => {
          this.loadData()
        }).finally(() => {
          this.$loading(false)
        })
      })
    },
  }
}
</script>

<style scoped>

</style>