<template>
  <div class="sg-top-overlay" ref="sgTopOverlay">
    <div :class="{
      'container is-max-widescreen': !isGapless,
      'px-2': isGapless
    }">
      <div class="sg-top-overlay-row">
        <router-link :to="backUrl" class="has-text-default has-text-weight-bold mr-4 is-flex is-align-items-center back-url" v-if="backUrl">
          <b-icon icon="chevron-left" class="has-text-primary mr-2"/>
          Kembali ke menu
        </router-link>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SgTopOverlay",
  props: {
    backUrl: String,
  },
  computed: {
    isGapless() {
      return this.$route.meta.isGapless
    },
  },
  mounted() {
    this.$store.commit('UPDATE_NAVBAR')
  },
}
</script>
